import React, { useEffect, useState } from "react";
import { Row, Col, Input, Divider, Select, Switch } from "antd";
import "./StepTwo.css";
import { MaskedInput } from "antd-mask-input";
import InputMask from "react-input-mask";
import UiForm from "components/UiForm/UiForm";
import UiFormSingle from "components/UiForm/UiFormSingle";
const StepTwo = (props) => {
  const {
    userData,
    setUserData,
    errorField,
    setErrorField,
    homePhone,
    setHomePhone,
    cellPhone,
    setCellPhone,
    contactPhone,
    setContactPhone,
    acontactPhone,
    setAContactPhone,
    aCellPhone,
    aHomePhone,
    setAHomePhone,
    setACellPhone,
    regType,
    curDomain,
    uiList
  } = props;
  const [parentFormList, setParentFormList] = useState([]);
  const [contactFormList, setContactFormList] = useState([]);
  useEffect(() => {
    if (uiList.length > 0) {
      let tmpPList = [...uiList.filter((x) => x.group_type == "2")]
      setParentFormList(tmpPList)
      let tmpCList = [...uiList.filter((x) => x.group_type == "3")]
      setContactFormList(tmpCList)
    }
  }, [uiList])
  const { Option } = Select;
  return (
    <div className="profile-info-widget">
      {(contactFormList.length > 0 || parentFormList.length > 0) && <div>
        <span className="title">Step 2: Enter Parent/Guardian Info (As it appears on credit card)</span>
        <Divider></Divider>
      </div>}

      {parentFormList.length > 0 && <div className="block">
        <div>
          <Row justify="space-between">
            <Col>
              <span className="title"> {parentFormList.length > 0 ? parentFormList[0].group_title : "Parent/Guardian"}</span>
            </Col>
            <Col>
              <span
                className="title"
                style={{ cursor: "pointer", display: "none" }}
              >
                Close
              </span>
            </Col>
          </Row>
          <Divider
            style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
          ></Divider>
        </div>

        <div style={{ marginTop: 20 }}>
          <Row gutter={[16, 16]}>
            {parentFormList.map((element, fIndex) => {
              return <UiFormSingle
                key={fIndex}
                index={''}
                recordList={[]}
                setRecordList={undefined}
                element={element}
                errorField={errorField}
                setErrorField={setErrorField}
                userData={userData}
                setUserData={setUserData}
                data={userData}
              />
            })}
          </Row>
        </div>
      </div>}
      {contactFormList.length > 0 && <div className="block" style={{ marginTop: 40 }}>
        <div>
          <Row justify="space-between">
            <Col>
              <span className="title">{contactFormList.length > 0 ? contactFormList[0].group_title : "Additional Contact Info"}</span>
            </Col>
            <Col>
              <span
                className="title"
                style={{ cursor: "pointer", display: "none" }}
              >
                Close
              </span>
            </Col>
          </Row>
          <Divider
            style={{ borderTop: "1px solid rgb(151, 196, 217, 71%)" }}
          ></Divider>
        </div>
        <div style={{ marginTop: 20 }}>
          <Row gutter={[16, 16]}>
            {contactFormList.map((element, fIndex) => {
              return <UiFormSingle
                key={fIndex}
                index={''}
                recordList={[]}
                setRecordList={undefined}
                element={element}
                errorField={errorField}
                setErrorField={setErrorField}
                userData={userData}
                setUserData={setUserData}
                data={userData}
              />
            })}
          </Row>
        </div>
      </div>}

    </div>
  );
};

export default StepTwo;
